import { sync } from 'vuex-pathify';

import { cloneDeep } from 'lodash';

export default {
  data: () => ({
    isFilterBuilderVisible: false,
    isFilterBuilderLoaded: false,
    isFilterHeadersVisible: false,
    filters: {
      builder_filter: undefined,
      headers_filter: {},
      search_filter: '',
      navbar_filter: {},
      sidebar_filter: {},
      additional_filter: undefined,
    },
    isFilterBuilderValid: false,
    containsFilter: undefined,
    defaultColDef: {
      filter: true,
      floatingFilter: true,
      suppressMenu: true,
      sortable: true,
      floatingFilterComponent: 'TableFloatingFilter',
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    isBuilderFilterActive: false,
    isHeadersFilterActive: false,
    isSearchFilterActive: false,
    isFilterActive: false,
    isFilterSelectedActive: false,
    isAllRowsSelected: false,
  }),
  computed: {
    cutoffLayersFilters: sync('locks/cutoffLayersFilters'),
  },
  methods: {
    setAdditionalFilters(sidebarFilterValues) {
      this.filters.additional_filter = sidebarFilterValues;
      this.handleFilter();
    },
    setSidebarFilters(sidebarFilterValues) {
      this.filters.sidebar_filter = Object.keys(sidebarFilterValues).reduce((total, key) => {
        return { ...total, [key]: sidebarFilterValues[key] };
      }, {});
      this.handleFilter();
    },
    setNavbarFilters(additionalTableFilterValues, init) {
      this.filters.navbar_filter = Object.keys(additionalTableFilterValues).reduce((total, key) => {
        return { ...total, [key]: additionalTableFilterValues[key] };
      }, {});
      this.handleFilter(init);
    },
    getBuilderFilterActive() {
      return !!this.filters.builder_filter;
    },
    getHeadersFilterActive() {
      return (
        this.filters.headers_filter &&
        this.filters.headers_filter.constructor === Object &&
        Object.keys(this.filters.headers_filter).length > 0
      );
    },
    getNavarFilterActive() {
      return (
        this.filters.navbar_filter &&
        this.filters.navbar_filter.constructor === Object &&
        Object.keys(this.filters.navbar_filter).filter(key => !!this.filters.navbar_filter[key]).length > 0
      );
    },
    getSearchFilterActive() {
      return !!this.filters.search_filter;
    },
    getFilterActive() {
      return (
        this.getBuilderFilterActive() ||
        this.getHeadersFilterActive() ||
        this.getSearchFilterActive() ||
        this.getNavarFilterActive()
      );
    },
    getBaseCutoffFilter() {
      return this.cutoffLayersFilters[this.layerId].filterResult;
    },
    getBuilderFilterExpression() {
      return this.filters.builder_filter;
    },
    getSearchFilterExpression() {
      if (!this.filters.search_filter) {
        return;
      }
      const columns = this.gridOptions.columnApi.getColumnState();
      if (columns.length < 1) {
        return false;
      } else if (columns.length === 1) {
        const attributeName = this.isUseQualifiedNames
          ? columns[0].colId
          : `${this.currentLayerDataSourceName}.${columns[0].colId}`;
        return {
          $LIKE_CASE_INSENSITIVE: {
            [attributeName]: { value: this.filters.search_filter },
          },
        };
      } else {
        return {
          '!OR': columns.map(column => {
            const attributeName = this.isUseQualifiedNames
              ? column.colId
              : `${this.currentLayerDataSourceName}.${column.colId}`;
            return {
              $LIKE_CASE_INSENSITIVE: { [attributeName]: { value: this.filters.search_filter } },
            };
          }),
        };
      }
    },
    getSelectedFilterExpression() {
      if (!this.currentLayerSelectedFeatures || this.currentLayerSelectedFeatures.length < 1) {
        return false;
      }
      const attributeName = this.isUseQualifiedNames
        ? this.idPropertyName
        : `${this.currentLayerDataSourceName}.${this.idPropertyName}`;
      const filter = {
        $IN: {
          [attributeName]: this.currentLayerSelectedFeatures,
        },
      };
      return filter;
    },
    getHeadersFilterExpression() {
      const headersFilterKeys = Object.keys(this.filters.headers_filter);
      const headersFilterLength = headersFilterKeys.length;
      if (headersFilterLength < 1) {
        return false;
      } else {
        const preparedExpressions = headersFilterKeys.map(key => {
          const attributeName = this.isUseQualifiedNames ? key : `${this.currentLayerDataSourceName}.${key}`;
          return {
            [this.filters.headers_filter[key].type]: {
              [attributeName]: { value: this.filters.headers_filter[key].value },
            },
          };
        });
        return headersFilterLength === 1 ? preparedExpressions[0] : { '!AND': preparedExpressions };
      }
    },
    getNavbarFilterExpression() {
      const navbarFilterKeys = Object.keys(this.filters.navbar_filter).filter(
        key => !!this.filters.navbar_filter[key] && this.filters.navbar_filter[key].length > 0
      );
      const navbarFilterLength = navbarFilterKeys.length;
      if (navbarFilterLength < 1) {
        return false;
      } else {
        const preparedExpressions = navbarFilterKeys.map(key => {
          const attributeName = this.isUseQualifiedNames ? key : `${this.currentLayerDataSourceName}.${key}`;
          return {
            $IN: {
              [attributeName]: { value: this.filters.navbar_filter[key] },
            },
          };
        });
        return navbarFilterLength === 1 ? preparedExpressions[0] : { '!AND': preparedExpressions };
      }
    },
    getSidebarFilterExpression() {
      const sidebarFilterKeys = Object.keys(this.filters.sidebar_filter).filter(
        key => !!this.filters.sidebar_filter[key] && this.filters.sidebar_filter[key].length > 0
      );
      const sidebarFilterLength = sidebarFilterKeys.length;
      if (sidebarFilterLength === 0) {
        return false;
      }
      if (sidebarFilterLength === 1) {
        const attributeName = this.isUseQualifiedNames
          ? sidebarFilterKeys[0]
          : `${this.currentLayerDataSourceName}.${sidebarFilterKeys[0]}`;
        return {
          $IN: {
            [attributeName]: { value: this.filters.sidebar_filter[sidebarFilterKeys[0]] },
          },
        };
      }
      const expressions = Object.keys(sidebarFilterKeys).reduce((total, current) => {
        const attributeName = this.isUseQualifiedNames ? current : `${this.currentLayerDataSourceName}.${current}`;
        return [
          ...total,
          {
            $IN: {
              [attributeName]: { value: this.filters.sidebar_filter[current] },
            },
          },
        ];
      }, []);
      return { '!AND': expressions };
    },
    getAdditionalFilterExpression() {
      return this.filters.additional_filter;
    },
    handleGetFilter() {
      const filtersExpressions = [
        this.getBuilderFilterExpression(),
        this.getHeadersFilterExpression(),
        this.getSearchFilterExpression(),
        this.getNavbarFilterExpression(),
        this.getSidebarFilterExpression(),
        this.getAdditionalFilterExpression(),
        this.isFilterSelectedActive ? this.getSelectedFilterExpression() : false,
        ...(this.isCutoffLayer ? [this.getBaseCutoffFilter()] : []),
      ].filter(fEL => !!fEL);

      if (filtersExpressions.length === 0) {
        return;
      }
      if (filtersExpressions.length === 1) {
        return filtersExpressions[0];
      }
      const expressions = Object.keys(filtersExpressions).reduce((total, current) => {
        return [...total, filtersExpressions[current]];
      }, []);
      return { '!AND': expressions };
    },
    handleFilter(init = false) {
      const filterExpression = this.handleGetFilter();
      this.initTableDatasource({ features_filter: filterExpression, clearSelection: false });
      if (filterExpression && filterExpression.constructor === Object && Object.keys(filterExpression).length > 0) {
        const parseFilters = JSON.parse(
          JSON.stringify({ filters: this.filters, filterExpression, layerId: this.layerId })
        );
        this.$emit('setLayerFeaturesFilters', parseFilters);
      } else {
        this.$emit('deleteLayerFeaturesFilters', this.layerId);
      }
      if (this.isCustomLayer && !init) {
        this.$root.$emit(this.customLayerData.filterEventName);
      } else {
        this.$root.$emit('filterProjectLayer', this.layerId, filterExpression);
      }
      this.$root.$emit('updateLegendStylesCounts', { layersIds: [this.layerId] });
      this.isHeadersFilterActive = this.getHeadersFilterActive();
      this.isBuilderFilterActive = this.getBuilderFilterExpression();
      this.isSearchFilterActive = this.getSearchFilterActive();
      this.isFilterActive = this.getFilterActive();
      this.isFilterBuilderVisible = false;
    },
    toggleFilterHeadersAction() {
      this.floatingFilterOpened
        ? this.gridOptions.api.setFloatingFiltersHeight(0)
        : this.gridOptions.api.setFloatingFiltersHeight();
      this.floatingFilterOpened = !this.floatingFilterOpened;
      this.gridOptions.api.refreshHeader();
    },
    toggleFilterBuilderAction() {
      this.isFilterBuilderLoaded = true;
      this.isFilterBuilderVisible = !this.isFilterBuilderVisible;
    },
    handleCloseExpressionBuilder() {
      this.filters.builder_filter = this.layersFilters?.[this.layerId]?.filters
        ? cloneDeep(this.layersFilters[this.layerId].filters.builder_filter)
        : this.$refs?.builderFilter.clearAllFilteres();
    },
    toggleFilterClearAction() {
      const sidebarFilterCopy = JSON.parse(JSON.stringify(this.filters.sidebar_filter));
      const additionalFilterCopy = this.filters.additional_filter
        ? JSON.parse(JSON.stringify(this.filters.additional_filter))
        : undefined;
      this.filters = {
        builder_filter: undefined,
        headers_filter: {},
        search_filter: '',
        navbar_filter: {},
        sidebar_filter: sidebarFilterCopy,
        additional_filter: additionalFilterCopy,
      };
      this.handleFilter();
      this.gridOptions.api.refreshHeader();
      this.$refs.builderFilter?.clearAllFilteres();
    },
    toggleFilterSelectedAction() {
      this.isFilterSelectedActive = !this.isFilterSelectedActive;
      this.handleFilter();
    },
  },
};
