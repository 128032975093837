import { get, sync } from 'vuex-pathify';

import View from 'ol/View';
import { transform } from 'ol/proj';

import { defaultMinZoom } from '@/assets/js/variables';

import { toPng } from 'html-to-image';

export default {
  computed: {
    center: sync('map/center'),
    zoom: sync('map/zoom'),
    tableHeight: sync('table/tableHeight'),
    isQuickPrintToolActive: sync('tools/toolStatus@isQuickPrintToolActive'),
    currentTab: sync('sidebar/currentTab'),
    isMapViewChanged: sync('map/isMapViewChanged'),
    mapRotationModuleEnabled: get('admin/additionalModules@MAP_ROTATION_MODULE.enabled'),
    projectMapRotation: sync('layers/project@rotation'),
  },
  methods: {
    setMinZoom(zoom = defaultMinZoom) {
      this.map.getView().setMinZoom(zoom);
    },
    prepareView() {
      return new View({
        projection: this.$_config.defaultEpsg,
        center: this.center,
        zoom: this.zoom,
        minZoom: defaultMinZoom,
        constrainResolution: true,
        constrainRotation: false,
        extent: this.$_config.defaultEpsgExtent,
      });
    },
    prepareOverviewMapView() {
      return new View({
        projection: this.$_config.defaultEpsg,
        constrainResolution: true,
      });
    },
    setNextView() {
      if (this.isNextViewDisabled) {
        return;
      }
      ++this.currentViewIndex;
      this.changeView(this.currentViewIndex);
    },
    setPreviousView() {
      if (this.isPreviousViewDisabled) {
        return;
      }
      --this.currentViewIndex;
      this.changeView(this.currentViewIndex);
    },
    changeView(index) {
      this.isSaveView = false;
      const { zoom, center } = this.views[index];
      this.setView({ zoom, center });
      this.isMapViewChanged = true;
    },
    setView({ zoom, center, options = { duration: 250 } }) {
      this.map.getView().animate({ zoom, center, ...options });
    },
    fitView(geometry, options = {}) {
      if (options.isDrawMarker) {
        this.drawMarker(options.markerGeometry, options);
      }
      this.map.getView().fit(geometry, { duration: 500, maxZoom: 20, ...options });
    },
    setDefaultProjectView() {
      const { zoom, map_center } = this.defaultProject;
      const transformedMapCenter = transform(
        map_center.coordinates,
        map_center.crs.properties.name,
        this.$_config.defaultEpsg
      );
      this.setView({ zoom: zoom, center: transformedMapCenter });
    },
    zoomView(isZoomIn) {
      const view = this.map.getView();
      const delta = 1;
      const zoom = isZoomIn ? view.getZoom() + delta : view.getZoom() - delta;
      if (view.getMinZoom() > zoom) {
        this.$store.set('snackbar/PUSH_ERROR!', {
          message: this.$i18n.t('errors.map.maxZoomReached'),
        });
        return;
      }
      this.setView({ zoom });
    },
    quickPrint() {
      const tableHeight = this.tableHeight;
      this.tableHeight = 0;
      this.isQuickPrintToolActive = true;
      this.map.updateSize();
      this.map.once('rendercomplete', () => {
        toPng(this.map.getViewport()).then(canvURL => {
          // prymitywny fix na firefoxa nakładający element tylko po wysokość
          let image = new Image();
          image.src = canvURL;
          image.setAttribute('class', 'printable-map-wrapper--image');
          document.querySelector('.printable-map-wrapper').appendChild(image);
          //
          document.querySelector('.printable-map-wrapper').style.backgroundImage = `url(${canvURL})`;
          document.body.classList.add('one-page-body');
          this.$nextTick(() => {
            window.print();
          });
        });
      });
      window.onafterprint = () => {
        document.body.classList.remove('one-page-body');
        document.querySelector('.printable-map-wrapper').innerHTML = '';
        document.querySelector('.printable-map-wrapper').style.backgroundImage = 'none';
        this.isQuickPrintToolActive = false;
        this.tableHeight = tableHeight;
      };
    },
    rotateMapByDegress(value = 0) {
      if ((value || value === 0) && value >= 0 && value <= 360) {
        this.map?.getView().setRotation((value * Math.PI) / 180);
      } else {
        this.map?.getView().setRotation(0);
      }
    },
  },
  mounted() {
    if (this.mapRotationModuleEnabled) {
      this.$watch(
        vm => vm.projectMapRotation,
        nV => {
          this.rotateMapByDegress(nV);
        },
        {
          immediate: true,
        }
      );
    }
  },
};
