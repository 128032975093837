import { get } from 'vuex-pathify';

export default {
  computed: {
    metadata: get('layers/metadata'),
    relationValuesMapping: get('attributesSchema/relationValuesMapping'),
    currentLayerDataSource: get('layers/currentLayer@data_source_name'),
    currentLayerFormSchema: get('layers/currentLayer@form_schema.elements'),
    attributesMetadata: get('layers/metadata@:currentLayerDataSource.attributes_schema.attributes'),
  },
  methods: {
    getForm(
      elements,
      metadata,
      {
        filteredOutAttributes = [],
        returnDictRelationInfo = false,
        predefinedItems,
        disabledAttributes = [],
        skipFilterExpressionRelation = false,
        withOriginalDataType = false,
      } = {}
    ) {
      const hasLayerMetadata = !!elements;
      return (hasLayerMetadata
        ? elements.filter(
            element =>
              (element.element_type === 'group' && element.elements.length > 0) ||
              (element.element_type === 'attribute_holder' && !filteredOutAttributes.includes(element.attribute))
          )
        : metadata.filter(element => !filteredOutAttributes.includes(element.name))
      ).map(element => {
        if (element.element_type === 'group') {
          return {
            ...element,
            elements: this.getForm(element.elements, metadata, {
              filteredOutAttributes,
              returnDictRelationInfo,
              predefinedItems,
              disabledAttributes,
              skipFilterExpressionRelation,
              withOriginalDataType,
            }),
          };
        }
        const {
          type,
          data_type: dataType,
          measurement_unit,
          dict_colors,
          allowed_values,
          allowed_relations,
          read_only: readonly,
          relation,
          nullable,
          dict_id,
          variable_relation_pointer,
          id,
        } = this.getAttribute(hasLayerMetadata ? element.attribute : element.name, metadata);
        const isSelectInput =
          type === 'dict' ||
          type === 'variable_relation_pointer' ||
          type === 'relation' ||
          type === 'variable_relation';
        let items = undefined;
        if (predefinedItems && Object.keys(predefinedItems).includes(element.attribute)) {
          items = predefinedItems[element.attribute];
        } else if (isSelectInput) {
          items =
            type === 'relation'
              ? this.getRelationalDict(relation.attribute, relation.data_source, relation.representation)
              : this.getDictItems(
                  type,
                  (type === 'variable_relation' ? undefined : allowed_relations) || allowed_values
                );
        }
        const hasColors =
          isSelectInput &&
          dict_colors &&
          dict_colors.length === (allowed_relations || allowed_values).length &&
          dict_colors.every(color => Boolean(color));
        const editable =
          !disabledAttributes.includes(element.attribute) && type !== 'variable_relation_pointer' && !readonly;
        return {
          ...(hasLayerMetadata
            ? element
            : {
                attribute: element.name,
                label: element.verbose_name,
                element_type: 'attribute_holder',
                required: !nullable,
              }),
          id,
          editable,
          unit: this.getSymbol(measurement_unit),
          colors: hasColors ? dict_colors : undefined,
          items,
          dataType: this.getDataType(
            isSelectInput,
            dataType,
            hasColors,
            type,
            skipFilterExpressionRelation ? undefined : relation
          ),
          ...(withOriginalDataType ? { dataTypeOriginal: dataType } : {}),
          icon: ['datetime', 'date'].includes(dataType.name)
            ? 'mdi-calendar'
            : dataType.name === 'time'
            ? 'mdi-clock-outline'
            : '',
          rules: this.getRules({
            required: hasLayerMetadata ? element.required : !nullable,
            maxLength: dataType.max_length,
            readonly,
            type,
            items,
            editable,
          }),
          nullable,
          ...(returnDictRelationInfo
            ? { isDict: dict_id || dict_id === 0, isRelation: relation && relation.attribute ? true : false }
            : {}),
          attributeType: type,
          ...(type === 'variable_relation'
            ? { allowedRelations: allowed_relations, variableRelationPointer: variable_relation_pointer }
            : {}),
          ...(relation?.filter_expression && !skipFilterExpressionRelation ? { relation } : {}),
        };
      });
    },
    getRelationalDict(attribute, datasource, representation) {
      return this.$store.get('attributesSchema/relationValuesMapping')[datasource]?.[attribute]?.[representation] || [];
    },
    getAttribute(attribute, metadata = this.attributesMetadata) {
      return metadata.find(attr => attr.name === attribute);
    },
    getSymbol(unit) {
      return unit ? unit.symbol : null;
    },
    getRules({ readonly, required, maxLength, type, items, editable }) {
      const rules = [];
      if (readonly) {
        return rules;
      }
      if (required) {
        rules.push(v => !!v || v === false || v === 0 || 'rules.required');
      }
      if (maxLength) {
        rules.push(v => !v || v.length <= maxLength || 'rules.maxLengthExceeded');
      }
      if (type === 'relation' && editable) {
        rules.push(v => v === '' || v === null || !!items.find(vv => vv.value === v) || 'rules.required');
      }
      return rules;
    },
    getDataType(isSelectInput, dataType, hasColors, type, relation) {
      const getDataTypeName = (type, relation) => {
        if (type === 'variable_relation') return 'variableRelation';
        else if (relation?.filter_expression) return 'filterExpressionRelation';
        else if (relation?.data_source === 'users_users') return 'user';
        else if (hasColors) return 'selectcolor';
        else return 'select';
      };
      return isSelectInput
        ? {
            ...dataType,
            name: getDataTypeName(type, relation),
          }
        : dataType;
    },
    getDictItems(type, items) {
      if (type === 'variable_relation_pointer') {
        return items.map(({ data_source, data_source_verbose_name }) => ({
          value: data_source,
          text: data_source_verbose_name,
        }));
      }
      return items;
    },
    getMappedDictItems(dict) {
      if (!dict) return;
      return Object.entries(dict)?.map(([key, value]) => ({
        value: key,
        text: value,
      }));
    },
    getValues(elements, valuesObject, skipNotEditable = false) {
      return elements.reduce((total, element) => {
        if (element.element_type === 'group') {
          return { ...total, ...this.getValues(element.elements, valuesObject, skipNotEditable) };
        }
        return {
          ...total,
          ...(skipNotEditable && !element.editable
            ? {}
            : { [element.attribute]: this.getValue(element.attribute, element.default_value_policy, valuesObject) }),
        };
      }, {});
    },
    getValue(attribute, defaultValuePolicy, valuesObject) {
      if (this.isNew && defaultValuePolicy) {
        return this.getDefaultValueHandler(defaultValuePolicy);
      }
      let value = null;
      if (valuesObject?.[attribute] || valuesObject?.[attribute] === false || valuesObject?.[attribute] === 0) {
        value = valuesObject[attribute];
      } else if (
        this.properties?.[attribute] ||
        this.properties?.[attribute] === false ||
        valuesObject?.[attribute] === 0
      ) {
        value = this.properties[attribute];
      } else if (this.feature?.[attribute] || this.feature?.[attribute] === false || valuesObject?.[attribute] === 0) {
        value = this.feature[attribute];
      }
      return value;
    },
    getFlattenProperties(values, idPropertyName = this.idPropertyName) {
      return {
        ...values.properties,
        // in some cases id property has different name than id but its value is available under id key in fetched feature
        [idPropertyName]: this.getIdValue(values, idPropertyName),
      };
    },
    getIdValue(values, idPropertyName) {
      return this.$_hasAnyValue(values.id) ? values.id : values.properties.id || values.properties[idPropertyName];
    },
    getParsedAttributeValue(value, dataType) {
      const dataTypeName = dataType.name;
      if (dataTypeName === 'datetime') {
        return this.$dayjs(value).format('L, LT');
      } else if (dataTypeName === 'date') {
        return value ? this.$dayjs(value).format('L') : null;
      } else if (dataTypeName === 'time') {
        return this.$dayjs(value, 'HH:mm:ss').format('LTS');
      } else if (dataTypeName === 'boolean') {
        return value ? dataType.true || this.$i18n.t('inputs.true') : dataType.false || this.$i18n.t('inputs.false');
      }
      return value;
    },
    getAttributeTextValueByName(initialValue, attributeName, datasource, formatValue = false) {
      let textValue = initialValue;
      const datasourceAttributes = this.metadata[datasource]?.attributes_schema.attributes;
      const attributeData = datasourceAttributes.find(att => att.name === attributeName);
      if (attributeData?.relation) {
        const uniques = this.relationValuesMapping[attributeData.relation.data_source]?.[
          attributeData.relation.attribute
        ]?.[attributeData.relation.representation];
        textValue = uniques.find(unique => unique.value === initialValue)?.text;
      }
      return formatValue ? this.getParsedAttributeValue(textValue, attributeData.data_type) : textValue;
    },
    getAttributeTextValue(values, attribute) {
      let value = values[attribute.attribute];
      if (
        attribute.items &&
        attribute.items.every(item => {
          return typeof item === 'object' && Object.keys(item).includes('text') && Object.keys(item).includes('value');
        })
      ) {
        return attribute.items.find(item => item.value == value)?.text
          ? attribute.items.find(item => item.value == value)?.text
          : '';
      }
      value = this.getParsedAttributeValue(value, attribute.dataType);
      return value === null ? '' : value;
    },
  },
};
