import store from '@/store';

import { defaultAdminTab } from '@/assets/js/variables';

const getAdditionalModulesPermitted = async (
  names,
  configRoute = false,
  oneModuleRequired = false,
  isPublic = false
) => {
  if (!names || names.length < 1) {
    return true;
  }
  try {
    if (
      Object.keys(store.get('admin/additionalModules')).length === 0 ||
      names.find(name => store.get('admin/additionalModules')[name] === undefined)
    ) {
      await store.dispatch('admin/getAdditionalModules', isPublic);
    }
    return oneModuleRequired
      ? Boolean(
          names.find(
            name =>
              store.get('admin/additionalModules')[name]?.enabled &&
              (store.get('admin/additionalModules')[name]?.configured || configRoute)
          )
        )
      : Boolean(
          !names.find(
            name =>
              !store.get('admin/additionalModules')[name]?.enabled ||
              !(store.get('admin/additionalModules')[name]?.configured || configRoute)
          )
        );
  } catch (error) {
    console.log('  error', error);
    return false;
  }
};

const getSettingsEnabled = async (names, oneSettingRequired = false) => {
  if (names && names.length > 0) {
    await store.dispatch('admin/getSettings');
    return oneSettingRequired
      ? Boolean(names.find(name => store.get('admin/settingsValues')[name]))
      : Boolean(!names.find(name => !store.get('admin/settingsValues')[name]));
  }
  return true;
};

const getPermissions = (names, oneSettingRequired = false) => {
  if (names && names.length > 0) {
    return oneSettingRequired
      ? Boolean(names.find(name => store.get('users/toolsPermissions')[name]?.main_value > 0))
      : Boolean(!names.find(name => !store.get('users/toolsPermissions')[name]?.main_value > 0));
  }
  return true;
};

const beforeModuleRouteEnter = async (
  to,
  from,
  next,
  {
    notPermittedNext = defaultAdminTab,
    configRoute = false,
    additionalModulesNames = [],
    settingsNames = [],
    oneModuleRequired = false,
    oneSettingRequired = false,
    isPublic = false,
  } = {}
) => {
  const promissesArray = [
    getAdditionalModulesPermitted(additionalModulesNames, configRoute, oneModuleRequired, isPublic),
    getSettingsEnabled(settingsNames, oneSettingRequired),
    ...(isPublic ? [] : [getPermissions(additionalModulesNames, oneSettingRequired)]),
  ];
  const responses = await Promise.all(promissesArray);
  const isAllPermitted = responses.every(response => response);
  if (isAllPermitted) {
    next(to.params);
  } else {
    next({ name: notPermittedNext });
  }
  return isAllPermitted;
};

const beforeRouteEnter = async (to, from, next, callback) => {
  const { dispatch } = store;
  const isTokenValid = await dispatch('authentication/checkToken');
  if (isTokenValid) {
    const r = await dispatch('users/getCurrentUser');
    callback(r);
  } else {
    const { set } = store;
    set('authentication/SET_TOKEN!', null);
    next({
      name: 'login',
      query: {
        redirect: to.fullPath,
      },
    });
  }
};

const beforeMainPathEnter = async (to, from, next) => {
  if (to.query.ott) {
    // UKE login
    next({
      name: 'loginUke',
      query: { ott: to.query.ott },
    });
  } else {
    // Default login
    next({
      name: 'login',
    });
  }
  next();
};

export { beforeModuleRouteEnter, beforeRouteEnter, beforeMainPathEnter };
