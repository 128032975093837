/* eslint-disable */
/*
  Variables are stored in JS file, because there might be some cases where they
  will be required outside .vue file or before Vue instance had been initialized (no `this` access).
*/

const defaultAdminTab = 'cockpit';

const defaultEpsg = 'EPSG:3857';
const defaultEpsgName = 'EPSG:3857 (Google Mercator)';
const defaultSRID = 3857;
const defaultEpsgDefinition =
  '+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext  +no_defs';
const defaultEpsgExtent = [-20026376.39, -20048966.1, 20026376.39, 20048966.1];

const defaultFrontendCoordinateSystems = [
  {
    auth_srid: defaultSRID,
    name: defaultEpsgName,
    proj4text: defaultEpsgDefinition,
  },
  {
    auth_srid: 4326,
    name: 'EPSG:4326 (GPS)',
    proj4text: '',
  }
];

const minEditingZoom = 16;
const defaultMinZoom = 0;
const defaultMaxZoom = 28;

const systemAttributes = [
  'id',
  '_id',
  'history_count',
  'notes_count',
  'attachments_count',
  'create_datetime',
  'create_user',
  'update_datetime',
  'update_user',
  'geom',
];

export {
  defaultFrontendCoordinateSystems,
  defaultEpsg,
  defaultEpsgName,
  defaultEpsgDefinition,
  defaultAdminTab,
  defaultEpsgExtent,
  defaultSRID,
  defaultMinZoom,
  defaultMaxZoom,
  minEditingZoom,
  systemAttributes,
};
