<template>
  <data-input-select v-bind="{ ...inputBind }" v-model="computedValue" @change="updateRelationPointer">
    <template #item="{item}">
      <div @mouseenter="onHover(true, item)" @mouseleave="onHover(false, item)" class="text-body-2">
        {{ item.text }}
      </div>
    </template>
  </data-input-select>
</template>
<script>
import { call, get } from 'vuex-pathify';
import { debounce } from 'lodash';

import DataInputSelect from '@/components/DataInputSelect';
import topo from '@/mixins/topo';

export default {
  name: 'DataInputVariableRelation',
  mixins: [topo],
  components: {
    DataInputSelect,
  },
  props: {
    allowedRelations: {
      type: Array,
      default: () => {
        return [];
      },
    },
    attribute: {
      type: String,
    },
    dataType: {
      type: Object,
      required: true,
    },
    featureId: {
      type: [Number, String],
    },
    geometry: {
      type: Object,
      default: null,
    },
    isEditingOn: {
      type: Boolean,
      default: true,
    },
    layerId: {
      type: [Number, String],
      required: true,
    },
    value: {
      default: null,
    },
    values: {
      type: Object,
      default: () => {
        return {};
      },
    },
    variableRelationPointer: {
      type: String,
      default: '',
    },
  },
  computed: {
    dataSources: get('layers/metadata'),
    layers: get('layers/layers'),
    computedDataType() {
      return { ...this.dataType, name: 'select' };
    },
    computedFeatureId() {
      return this.featureId || this.featureId === 0 ? this.featureId : this.currentFeatureId;
    },
    currentFeatureId() {
      return this.$route?.params?.fid;
    },
    computedValue: {
      get() {
        if ((this.value || this.value === 0) && this.variableRelationPointerValue) {
          return `${this.variableRelationPointerValue}-${this.value}`;
        } else {
          return '';
        }
      },
      set(nV) {
        const { featureId } = nV || { featureId: null };
        if (featureId === null) {
          this.updateRelationPointer();
        }
        this.$emit('input', featureId);
      },
    },
    computedValues: {
      get() {
        return this.values;
      },
      set(nV) {
        this.$emit('update:values', nV);
      },
    },
    dataSource() {
      return this.dataSources[this.dataSourceName];
    },
    dataSourceName() {
      return this.layer?.data_source_name || '';
    },
    inputBind() {
      return {
        loading: this.loading,
        readonly: this.loading,
        ...this.$props,
        ...this.$attrs,
        dataType: this.computedDataType,
        itemValue: 'key',
        items: this.variableRelationItems,
        returnObject: true,
      };
    },
    layer() {
      return this.layers[this.layerId];
    },
    variableRelationPointerValue() {
      return this.variableRelationPointer
        ? this.computedValues?.[this.variableRelationPointer] || ''
        : this.allowedRelations?.[0]?.data_source || '';
    },
    variableRelationPointerVerboseName() {
      return this.dataSources[this.variableRelationPointerValue]?.verbose_name || '';
    },
  },
  data: () => ({
    copyValueItem: [],
    loading: false,
    variableRelationItems: [],
  }),
  methods: {
    getDataSourceFeatures: call('layers/getDataSourceFeatures'),
    getTopologicalParentObjects: call('layers/getTopologicalParentObjects'),
    async init() {
      if ((this.value || this.value === 0) && this.variableRelationPointerValue) {
        this.copyValueItem = JSON.parse(
          JSON.stringify([
            {
              featureId: this.value,
              text: `${this.variableRelationPointerVerboseName}: ${this.value}`,
              dataSourceName: this.variableRelationPointerValue,
            },
          ])
        );
      } else {
        this.copyValueItem = [];
      }
      if (!this.isEditingOn) {
        const hasFeatureId = this.featureId || this.featureId === 0;
        this.updateItems({ init: hasFeatureId });
      }
    },
    getNonTopoPromises({ relations = this.allowedRelations, geometry } = {}) {
      const filtersPromises = relations
        .filter(relation => this.dataSources[relation.data_source] || false)
        .map(relation => {
          const { data_source: dataSourceName } = relation;
          const dataSource = this.dataSources[dataSourceName];
          const geomAttributeName = dataSource.attributes_schema.geometry_name;
          const useQualifiedNames = dataSource.attributes_use_datasource_qualified_names;
          const geomName = useQualifiedNames ? geomAttributeName : `${dataSourceName}.${geomAttributeName}`;
          const filters = {
            '%SPATIALLY_INTERSECTS': {
              [geomName]: [geometry],
            },
          };
          const payload = {
            dataSource: dataSourceName,
            filters,
          };
          return this.getDataSourceFeatures(payload);
        });
      return filtersPromises;
    },
    getTopoPromises({ dataSource = this.dataSource, attributeName = this.attribute, geometry } = {}) {
      const { name: dataSourceName } = dataSource;
      const payload = {
        dataSourceName,
        attributeName,
        geometry,
      };
      return [this.getTopologicalParentObjects(payload)];
    },
    getParentObjectsPromises({
      isTopo = this.isTopologicalDataSource(this.dataSource),
      relations = this.allowedRelations,
      geometry,
      dataSource = this.dataSource,
      attributeName = this.attribute,
    }) {
      if (isTopo) {
        return this.getTopoPromises({ dataSource, attributeName, geometry });
      } else {
        return this.getNonTopoPromises({ relations, geometry });
      }
    },
    getResponseFeatures({ isTopo = this.isTopologicalDataSource(this.dataSource), responseData }) {
      let rItems = [];
      if (isTopo) {
        responseData = responseData[0];
        Object.keys(responseData).forEach(dataSourceName => {
          const rElement = responseData[dataSourceName].features;
          const items = this.getFeaturesItems(rElement, dataSourceName);
          rItems = [...rItems, ...items];
        });
      } else {
        responseData.forEach((rElement, index) => {
          const relation = this.allowedRelations[index];
          const { data_source: dataSourceName } = relation;
          const items = this.getFeaturesItems(rElement, dataSourceName);
          rItems = [...rItems, ...items];
        });
      }
      return rItems;
    },
    getFeaturesItems(element, dataSourceName) {
      const dataSourceVerboseName = this.dataSources[dataSourceName]?.verbose_name || '';
      const { crs, features } = element;
      const items = features.map(feature => {
        const { id: featureId, geometry } = feature;
        return {
          featureId,
          text: `${dataSourceVerboseName}: ${featureId}`,
          dataSourceName,
          geometry: { ...geometry, crs },
        };
      });
      return items;
    },
    async updateItems({ geometry = this.geometry, init = false } = {}) {
      this.loading = true;
      let rItems = [];
      if (!init) {
        const promises = this.getParentObjectsPromises({ geometry });
        const r = await Promise.all(promises);
        rItems = this.getResponseFeatures({ responseData: r });
      }
      const items = Array.from(new Set([...this.copyValueItem, ...rItems]));
      this.variableRelationItems = items.map(item => {
        const { dataSourceName, featureId } = item;
        return { ...item, key: `${dataSourceName}-${featureId}` };
      });

      if (
        !this.variableRelationItems.find(
          item => item.dataSourceName === this.variableRelationPointerValue && item.featureId === this.value
        )
      ) {
        this.computedValue = null;
        this.updateRelationPointer(null);
      }
      this.loading = false;
    },
    updateRelationPointer(nV) {
      const { dataSourceName } = nV || { dataSourceName: '' };
      if (this.variableRelationPointer) {
        this.computedValues = { ...this.computedValues, [this.variableRelationPointer]: dataSourceName };
      }
    },
    onHover(value, data) {
      if (value && !data.geometry) {
        return;
      }
      this.$root.$emit('toggleVariableRelationHover', data, value);
    },
  },
  watch: {
    geometry: {
      handler(nV) {
        if (!nV) {
          this.updateItems({ init: true });
        }
        if (this.isEditingOn) {
          this.updateItems({ geometry: nV });
        }
      },
      deep: true,
      immediate: false,
    },
    isEditingOn: {
      handler(nV) {
        if (nV) {
          this.updateItems();
        }
      },
      immediate: true,
    },
  },
  created() {
    this.updateItems = debounce(this.updateItems, 1000);
  },
  mounted() {
    this.init();
  },
};
</script>
