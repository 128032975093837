<template>
  <div class="table-height-button-wrapper">
    <v-tooltip v-for="button in tableHeightButtons" :key="button.name" bottom color="font">
      <template #activator="{ on }">
        <v-btn
          v-on="on"
          icon
          outlined
          small
          style="width: 24px; height: 24px;"
          :disabled="button.disabled"
          class="table-height-button"
          elevation="1"
          @click="button.action"
        >
          <v-icon dark small>
            {{ button.icon }}
          </v-icon>
        </v-btn>
      </template>
      {{ button.tooltip }}
    </v-tooltip>
  </div>
</template>

<script>
import { sync } from 'vuex-pathify';

export default {
  name: 'TableHeightButtons',
  computed: {
    tableHeight: sync('table/tableHeight'),
    isIncreaseTableHeightButtonDisabled() {
      return this.tableHeight === 3;
    },
    isDecreaseTableHeightButtonDisabled() {
      return this.tableHeight === 0;
    },
    tableHeightButtons() {
      return [
        {
          name: 'increaseTableHeight',
          icon: 'mdi-chevron-up',
          disabled: this.isIncreaseTableHeightButtonDisabled,
          action: this.increaseTableHeightAction,
          tooltip: this.isIncreaseTableHeightButtonDisabled
            ? this.$i18n.t('button.increaseTableDisabled')
            : this.$i18n.t('button.increaseTable'),
        },
        {
          name: 'decreaseTableHeight',
          icon: 'mdi-chevron-down',
          action: this.decreaseTableHeightAction,
          disabled: this.isDecreaseTableHeightButtonDisabled,
          tooltip: this.isDecreaseTableHeightButtonDisabled
            ? this.$i18n.t('button.decreaseTableDisabled')
            : this.$i18n.t('button.decreaseTable'),
        },
      ];
    },
  },
  methods: {
    increaseTableHeightAction() {
      if (this.tableHeight < 3) {
        this.tableHeight++;
      }
    },
    decreaseTableHeightAction() {
      if (this.tableHeight > 0) {
        this.tableHeight--;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.table-height-button {
  transform: translateY(-50%);
  border: 0.8px solid rgba(0, 0, 0, 0.2) !important;
  background-color: #fff;
}
.table-height-button-wrapper {
  height: 0;
  gap: 0.1rem;
  position: fixed;
  z-index: 4;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
</style>
