<template>
  <v-list-item
    :class="{ active: computedIsActive, 'module-disable-list': isDisabled, 'px-0': !styleName, 'pl-1': styleName }"
  >
    <slot :name="path" v-bind="$props">
      <v-list-item-icon v-if="styleName" class="mx-2">
        <v-icon size="21" color="rgba(0,0,0, 0.7)" v-text="icon" />
      </v-list-item-icon>
      <v-col cols="1" v-if="isSubListChild"></v-col>
      <v-list-item-content :class="{ 'px-4': !styleName }">
        <v-list-item-title>
          <v-tooltip bottom color="font" :disabled="!isAdmin || additionalModuleName.toString().length < 23">
            <template #activator="{ on }">
              <v-subheader
                style="height: auto"
                class="pa-0"
                v-on="{ ...$listeners, ...on }"
                :class="{
                  'text-body-1': isAdmin,
                  'font--text': (isToolWorking || !isAdmin) && !isDisabled,
                  'module-disable-element': isDisabled,
                  'text-subtitle-2': !isAdmin,
                  'font-weight-regular': !isAdmin,
                }"
              >
                <div class="text-truncate">
                  {{ additionalModuleName }}
                </div>
              </v-subheader>
            </template>
            {{ additionalModuleName }}
          </v-tooltip>
        </v-list-item-title>
      </v-list-item-content>
      <v-list-item-icon v-if="hasAnchor">
        <v-icon size="19" color="rgba(0,0,0, 0.7)">mdi-menu-right</v-icon>
      </v-list-item-icon>
    </slot>
  </v-list-item>
</template>

<script>
export default {
  name: 'TheNavbarTopPanelMenuItem',
  props: {
    hasDivider: {
      type: Boolean,
      default: false,
    },
    hasAnchor: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: '',
    },
    path: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    isSubListChild: {
      type: Boolean,
      default: false,
    },
    styleName: {
      type: String,
      default: '',
    },
    routeParams: {
      type: Object,
      default: () => {},
    },
    isRegister: {
      type: Boolean,
      default: false,
    },
    isAuthorized: {
      type: Boolean,
      default: false,
    },
    hasCustomAction: {
      type: Boolean,
      default: false,
    },
    hasState: {
      type: Boolean,
      default: false,
    },
    state: {
      type: Boolean,
      default: null,
    },
    isToolWorking: {
      type: Boolean,
      default: true,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    isList: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isActive: {
      type: Boolean,
      default: null,
    },
    verboseName: {
      type: String,
      default: '',
    },
  },
  computed: {
    route() {
      return this.$route.name;
    },
    paramName() {
      return this.$route.params.type;
    },
    computedIsActive() {
      if (this.isActive !== null) {
        return this.isActive;
      }
      if (this.isList) {
        return false;
      }
      if (this.routeParams?.type) {
        return this.routeParams.type === this.paramName;
      } else {
        return this.styleName === this.route;
      }
    },
    additionalModuleName() {
      return this.verboseName || this.$i18n.t(`navbar.${this.name}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.active {
  background-color: #b1b1b147;
}
.module-disable-element {
  cursor: text;
}
.module-disable-list:hover {
  background-color: white;
  cursor: default;
}
</style>
