<template>
  <sidebar-row
    :has-icon-column="hasIconColumn"
    :class="{ 'floating-title': isFloating, 'scrolled-floating-title': isFloating && isScrolled }"
    :align-class="alignClass"
    :padding-right="paddingRight"
  >
    <template #actionIcon v-if="hasBackButton">
      <v-btn small icon style="width: auto" @click="$emit('action')">
        <v-icon :color="$_colors.iconIdle" size="24"> mdi-chevron-left </v-icon>
      </v-btn>
    </template>
    <template #default>
      <v-row no-gutters align="center" class="flex-nowrap" :class="{ 'grid-title': !disableTitleButtonsGap }">
        <v-col cols="auto" v-bind="{ ...(disableTitleButtonsGap ? { cols: 'auto' } : {}) }">
          <v-row no-gutters align="center">
            <v-col cols="auto">
              <v-row no-gutters>
                <v-col class="d-flex">
                  <v-tooltip bottom color="font" :class="{ 'text--disabled': true }" :disabled="!isContentWrapped">
                    <template #activator="{ on }">
                      <div
                        v-if="featureTitle"
                        v-on="on"
                        v-isContentWrapped="{ method: setIsContentWrapped }"
                        style="max-width: 100%; text-align: left; line-height: 0"
                      >
                        <span
                          :class="[titleSize, ...titleClasses]"
                          class="text-truncate"
                          style="max-width: 100%; line-height: 1.5rem"
                        >
                          {{ featureTitle.title || 'null' }}
                        </span>
                        <template v-if="featureTitle.subtitle">
                          <br />
                          <span class="text-truncate text-caption font-italic font-weight-light pr-1">
                            {{ featureTitle.subtitle }}
                          </span>
                        </template>
                      </div>
                      <div
                        v-else
                        v-isContentWrapped="{ method: setIsContentWrapped }"
                        v-on="on"
                        style="max-width: 100%"
                        :class="[titleSize, ...titleClasses]"
                      >
                        {{ title }}
                      </div>
                    </template>
                    {{ title }}
                  </v-tooltip>
                </v-col>
              </v-row>
              <v-row no-gutters v-if="hasSubtitleSlot">
                <v-col cols="12">
                  <slot name="subtitle"></slot>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="auto">
              <slot name="postTitle"></slot>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="auto" class="pl-2" v-if="hasButtons">
          <slot name="buttons"></slot>
        </v-col>
      </v-row>
      <v-row v-if="hasToolsButtons && Object.keys($slots).includes('toolsButtons')">
        <v-col cols="auto">
          <slot name="toolsButtons"></slot>
        </v-col>
      </v-row>
    </template>
  </sidebar-row>
</template>

<script>
import isContentWrapped from '@/mixins/directives/isContentWrapped';
import SidebarRow from '@/components/SidebarRow';

export default {
  name: 'CardTitleRow',
  components: {
    SidebarRow,
  },
  mixins: [isContentWrapped],
  props: {
    hasButtons: {
      type: Boolean,
      default: true,
    },
    hasToolsButtons: {
      type: Boolean,
      default: false,
    },
    buttonsAlign: {
      type: String,
      validator: v => {
        return ['left', 'right'].includes(v);
      },
      default: 'right',
    },
    isSubtitle: {
      type: Boolean,
      default: false,
    },
    translationPath: {
      type: String,
      default: '',
    },
    isPlural: {
      type: Boolean,
      default: false,
    },
    amount: {
      type: Number,
      default: 0,
    },
    straightTitle: {
      type: String,
      default: '',
    },
    featureTitle: {
      type: Object,
      default: null,
    },
    suffix: {
      type: String,
      default: '',
    },
    customSize: {
      type: String,
      default: '',
    },
    isRegular: {
      type: Boolean,
      default: false,
    },
    hasBackButton: {
      type: Boolean,
      default: false,
    },
    hasIconColumn: {
      type: Boolean,
      default: true,
    },
    titleClasses: {
      type: Array,
      default: () => [],
    },
    alignClass: {
      type: String,
      default: 'align-baseline',
    },
    isFloating: {
      type: Boolean,
      default: false,
    },
    paddingRight: {
      type: String,
      default: 'pr-5',
    },
    disableTitleButtonsGap: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isScrolled: false,
  }),
  computed: {
    translationMethod() {
      return this.isPlural ? 'tc' : 't';
    },
    hasSubtitleSlot() {
      return !!Object.keys(this.$slots).find(slot => slot.split('.')[0] === 'subtitle');
    },
    titleSize() {
      if (this.customSize) {
        return this.customSize;
      }
      return this.isSubtitle ? 'text-subtitle-2' : 'text-h6';
    },
    title() {
      return this.straightTitle
        ? this.straightTitle
        : this.featureTitle
          ? `${this.featureTitle.subtitle}: ${this.featureTitle.title}`
          : this.$i18n[this.translationMethod](this.translationPath, this.amount) + this.suffix;
    },
  },
  methods: {
    handleCardScroll() {
      this.isScrolled = document.querySelector('.legend').scrollTop > 0 ? true : false;
    },
  },
  mounted() {
    if (!this.isFloating || !document.querySelector('.legend')) return;
    document.querySelector('.legend').addEventListener('scroll', this.handleCardScroll);
  },
  beforeDestroy() {
    if (!document.querySelector('.legend')) return;
    document.querySelector('.legend').removeEventListener('scroll', this.handleCardScroll);
  },
};
</script>

<style lang="scss" scoped>
.grid-title {
  display: grid !important;
  grid-template-columns: minmax(0, 1fr) auto;
}
.floating-title {
  position: sticky;
  top: 39px !important;
  padding-bottom: 25px !important;
  z-index: 2;
  background-color: #fff;
  padding-top: 20px !important;
  transition: box-shadow 100ms linear;
}
.scrolled-floating-title {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
}
</style>
