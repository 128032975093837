<template>
  <data-input-select
    v-bind="{ ...$props, ...$attrs }"
    :value="computedValue"
    v-on="$listeners"
    :items="computedItems"
    item-text="text"
    item-value="value"
  ></data-input-select>
</template>

<script>
import DataInputSelect from '@/components/DataInputSelect';

export default {
  name: 'DataInputBoolean',
  components: {
    DataInputSelect,
  },
  props: {
    value: {
      default: () => {
        return false;
      },
    },
    trueText: {
      type: String,
      default: '',
    },
    falseText: {
      type: String,
      default: '',
    },
    descriptors: {
      required: false,
      type: Object,
    },
  },
  computed: {
    computedValue: {
      get() {
        if (this.value == null) {
          return undefined;
        }
        return !this.value == false;
      },
      set(nV) {
        this.$emit('input', nV);
      },
    },
    computedItems() {
      return [
        {
          value: true,
          text: this.descriptors?.true || this.trueText || this.$i18n.t('inputs.true'),
        },
        {
          value: false,
          text: this.descriptors?.false || this.falseText || this.$i18n.t('inputs.false'),
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
// .v-input {
//   font-family: Roboto;
//   font-size: 14px;
//   font-style: normal;
//   font-weight: 400;
//   line-height: 16px;
//   letter-spacing: 0em;
//   text-align: center;
// }
// ::v-deep .v-input__slot {
//   margin-bottom: 3px;
//   input {
//     padding: 4px 0 2px !important;
//   }
//   .v-input__icon--clear {
//     height: 20px;
//     .v-icon {
//       font-size: 19px !important;
//     }
//   }
// }
</style>
