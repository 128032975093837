export default {
  methods: {
    getInteractionByName(name) {
      return this.map
        .getInteractions()
        .getArray()
        .find(interaction => interaction.get('name') == name);
    },
    getLayerById(id, group) {
      if (!group) {
        return this.map
          ?.getLayers()
          .getArray()
          .find(layer => layer.get('id') == id);
      } else {
        return this.map
          .getLayers()
          .getArray()
          .find(g => g.get('id') == group)
          .getLayersArray()
          .find(layer => layer.get('id') == id);
      }
    },
    getControlByName(name) {
      return this.map
        .getControls()
        .getArray()
        .find(control => control.get('name') === name);
    },
    getLayerByIdEmit({ id, callback }) {
      callback(this.getLayerById(id));
    },
  },
  mounted() {
    this.$root.$off('getLayerById');
    this.$root.$on('getLayerContextById', this.getLayerByIdEmit);
  },
};
