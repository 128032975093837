<template>
  <v-col
    cols="12"
    class="v-treeview-node v-treeview-node--click pa-0"
    :class="{ 'v-treeview-node--leaf': hasAnyChild, 'v-treeview-expandable': hasExpandButton }"
  >
    <v-row no-gutters class="v-treeview-node__root flex-nowrap text-left pa-0" v-on="nodeListeners">
      <v-col
        v-if="!hasLayerStyles || isNestedExpandable"
        cols="auto"
        class="d-flex node-levels-box"
        :style="{ marginLeft: isNestedExpandable ? '-24px' : null }"
      >
        <div v-for="index in appendLevel" :key="index" class="v-treeview-node__level" />
      </v-col>
      <v-col cols="auto" v-if="hasExpandButton">
        <v-icon
          class="notranslate v-treeview-node__toggle v-icon--link"
          :class="{
            'v-treeview-node__toggle--open': isOpen,
          }"
        >
          {{ expandIcon }}
        </v-icon>
      </v-col>
      <v-col class="flex-grow-1" style="min-width: 0">
        <v-row no-gutters align="center" class="flex-nowrap">
          <v-col cols="auto">
            <slot name="prepend" v-bind="treeviewSlotsBind">
              <slot :name="`prepend-${level}`" v-bind="treeviewSlotsBind" />
            </slot>
          </v-col>
          <v-col cols="auto" class="flex-grow-1">
            <v-row no-gutters>
              <v-col cols="12" class="v-treeview-node__label">
                <slot name="label" v-bind="treeviewSlotsBind">
                  <slot :name="`label-${level}`" v-bind="treeviewSlotsBind">
                    {{ value[elementNameKey] }}
                  </slot>
                </slot>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="auto">
            <slot name="append" v-bind="treeviewSlotsBind">
              <slot :name="`append-${level}`" v-bind="treeviewSlotsBind" />
            </slot>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row
      no-gutters
      v-if="(isOpen || (isGroupElement && !hasAnyChild && emptyGroupTranslationPath)) && !hasLayerStyles"
      class="v-treeview-node__children v-treeview-node__children__draggable"
    >
      <draggable
        :group="draggableGroup"
        :value="value[elementChildrenKey]"
        :ghost-class="`treeview-draggable-ghost-${level + 1}`"
        class="treeview-draggable-box"
        @input="updateItems"
      >
        <treeview-draggable-node
          v-for="(child, index) in value[elementChildrenKey]"
          :key="child[elementUidKey]"
          :value="child"
          :group-id="groupId"
          :layer-id="layerId || index"
          :all-values="allValues"
          v-bind="treeviewNodeBind"
          v-on="nodeListeners"
          @input="updateItem"
        >
          <template v-for="(value, key) in $scopedSlots" #[key]="scope">
            <slot :name="key" v-bind="scope" />
          </template>
        </treeview-draggable-node>
        <template v-if="isGroupEmpty">
          <slot name="empty-group-box">
            <v-row no-gutters class="treeview-draggable-no-elements-box">
              <v-col cols="auto" class="treeview-draggable-no-elements">
                <v-row no-gutters style="min-height: 30px" align="center">
                  <v-col cols="auto" class="d-flex">
                    <div v-for="index in appendLevel" :key="index" class="v-treeview-node__level" />
                  </v-col>
                  <v-col cols="auto">
                    <slot name="empty-group-inner" v-bind="{ ...treeviewSlotsBind, emptyGroupTranslationPath }">
                      {{ $i18n.t(emptyGroupTranslationPath) }}
                    </slot>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </slot>
        </template>
      </draggable>
    </v-row>
  </v-col>
</template>
<script>
import draggable from 'vuedraggable-multi';
export default {
  name: 'TreeviewDraggableNode',
  components: { draggable },
  props: {
    value: {
      type: Object,
      required: true,
    },
    allValues: {
      type: Array,
      required: false,
      default: () => [],
    },
    elementChildrenKey: {
      type: String,
      default: 'children',
    },
    groupId: {
      type: Number,
      default: null,
    },
    layerId: {
      type: Number,
      default: null,
    },
    elementNameKey: {
      type: String,
      default: 'name',
    },
    elementUidKey: {
      type: String,
      default: 'id',
    },
    groupOpenKey: {
      type: String,
      default: null,
    },
    draggableGroup: {
      type: String,
      default: null,
    },
    emptyGroupTranslationPath: {
      type: String,
      default: 'default.noElementsInGroup',
    },
    expandIcon: {
      type: String,
      default: 'mdi-menu-down',
    },
    level: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    hasLayerStyles() {
      return !!this.value.style?.ranges || !!this.value.style?.uniques;
    },
    hasAnyChild() {
      return this.value[this.elementChildrenKey]?.length > 0;
    },
    hasExpandButton() {
      return this.hasLayerStyles || this.hasAnyChild;
    },
    isGroupElement() {
      return this.value[this.elementChildrenKey] ? true : false;
    },
    isGroupEmpty() {
      return this.isGroupElement && !this.hasAnyChild;
    },
    isNestedExpandable() {
      return this.hasLayerStyles && (!!this.layerId || this.layerId === 0);
    },
    appendLevel() {
      return this.level + (this.hasAnyChild ? 0 : 1);
    },
    isOpen() {
      return this.groupOpenKey ? this.value[this.groupOpenKey] : this.open;
    },
    nodeListeners() {
      return {
        ...((this.isGroupElement && !this.isGroupEmpty) || this.hasLayerStyles
          ? {
              click: () => {
                this.toggleOpen();
              },
            }
          : {}),
      };
    },
    treeviewSlotsBind() {
      return {
        item: this.value,
        ...(this.isGroupElement ? { open: this.isOpen } : {}),
      };
    },
    treeviewNodeBind() {
      return {
        draggableGroup: this.draggableGroup,
        expandIcon: this.expandIcon,
        elementChildrenKey: this.elementChildrenKey,
        elementNameKey: this.elementNameKey,
        elementUidKey: this.elementUidKey,
        groupOpenKey: this.groupOpenKey,
        emptyGroupTranslationPath: this.emptyGroupTranslationPath,
        level: this.level + 1,
      };
    },
  },
  data: () => ({
    open: false,
  }),
  methods: {
    toggleOpen() {
      if (this.groupOpenKey) {
        if (this.groupId && this.layerId) {
          const copyValues = [...this.allValues];
          copyValues[this.groupId][this.elementChildrenKey][this.layerId][this.groupOpenKey] =
            !copyValues[this.groupId][this.elementChildrenKey][this.layerId][this.groupOpenKey];
          this.$emit('input', copyValues[this.groupId]);
        } else {
          this.$emit('input', {
            ...this.value,
            [this.groupOpenKey]: !this.value[this.groupOpenKey],
          });
        }
      } else {
        this.open = !this.open;
      }
    },
    updateItem() {
      this.$emit('input', this.value);
    },
    updateItems(e) {
      this.$set(this.value, this.elementChildrenKey, e);
      this.$emit('input', this.value);
    },
  },
};
</script>
<style lang="scss" scoped>
.treeview-draggable-box {
  width: 100%;
  min-height: 30px;
  position: relative;
  .treeview-draggable-no-elements-box {
    position: absolute;
    top: 0;
    .treeview-draggable-no-elements {
      font-style: italic;
      font-size: 13px;
      opacity: 0.8;
      color: rgba(0, 0, 0, 0.6);
    }
    &:last-child:not(:first-child) {
      display: none;
    }
  }
  .sortable-chosen {
    background-color: white;
    z-index: 1;
  }
}
.v-treeview-node__root {
  align-items: flex-start;
}
</style>
